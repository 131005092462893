@import url("https://fonts.googleapis.com/css?family=Gochi+Hand");

$cassetteColor: rgba(255,255,255,0.5);
$stickerColor: white;
$screwColor: black;
$back: rgb(236,227,195);
$text: black;
$tape: brown;

@mixin dimentions($width, $height, $background) {
	width: $width;
	height: $height;
	background: $background;
}

@mixin pseudo {
	&::before,
	&::after {
		content: "";
		display: block;
	}
}


.cassette {
	@include dimentions(30vw, 20vw, $cassetteColor);
	border-radius: 0.5vw;
	position: relative;
	padding: 10px;
}

%screw {
	@include pseudo;
	@include dimentions(1vw, 1vw, $screwColor);
	border-radius: 1vw;
	position: absolute;
	&::before {
		@include dimentions(0.2vw, 0.5vw, $cassetteColor);
		margin: 0.25vw 0 0 0.4vw;
	}
	&::after {
		@include dimentions(0.5vw, 0.2vw, $cassetteColor);
		margin: -0.35vw 0 0 0.25vw;
	}
}

.screw1 {
	@extend %screw;
	left: 0.1vw;
	top: 0.1vw;
}

.screw2 {
	@extend %screw;
	right: 0.1vw;
	top: 0.1vw;
}

.screw3 {
	@extend %screw;
	left: 0.1vw;
	bottom: 0.1vw;
}

.screw4 {
	@extend %screw;
	right: 0.1vw;
	bottom: 0.1vw;
}

.sticker {
	@include dimentions(28vw, 14vw, $stickerColor);
	border-radius: 0.5vw;
	margin: .5vw auto 0;
	position: relative;
}

.songlabel {
	margin: auto;
	&::before {
		content: attr(name);
		font: 800 2vw/6vw "Roboto";
		color: $text;
		text-align: center;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&::after {
		content: "Side A";
		font: 1vw "Roboto";
		color: $text;
		margin: 0.5vw 0 0 1vw;
		display: block;
	}
}

.artistlabel {
	margin: auto;
	&::before {
		content: attr(name);
		font: 800 1.5vw/6vw "Roboto";
		color: $text;
		text-align: center;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.window {
	@include pseudo;
	@include dimentions(17vw, 4vw, $back);
	border: 0.3vw solid $cassetteColor;
	border-radius: 0.5vw;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	position: absolute;
	overflow: hidden;
	&::before,
	&::after {
		@include dimentions(5vw, 6vw, $cassetteColor);
		position: absolute;
		z-index: 1;
		top: -1vw;
	}
	&::before {
		left: -0.5vw;
	}
	&::after {
		right: -0.5vw;
	}
}

.reelleft,
.reelright {
	@include pseudo;
	@include dimentions(6vw, 6vw, rgb(255,255,255));
	border-radius: 5vw;
	margin: -1vw;
	position: absolute;
}

.reelleft {
	box-shadow: 0 0 0 3.2vw $tape;
	animation: spin 20s infinite linear alternate,
		shrink 20s infinite linear alternate;
}

.reelright {
	right: 0vw;
	box-shadow: 0 0 0 0.8vw $tape;
	animation: spin 20s infinite linear alternate forwards,
		grow 20s infinite linear alternate forwards;
}

.reelleft::before,
.reelright::before {
	@include dimentions(1vw, 0.5vw, rgb(255,0,0));
	position: absolute;
	left: 0;
	top: 2.7vw;
}

.reel {
	@include dimentions(16vw, 3vw, transparent);
	border-radius: 2vw;
	margin: 0.5vw auto;
	position: relative;
	& .outerreelleft {
		@include dimentions(3vw, 3vw, $back);
		border: 0.4vw dashed rgb(255, 255, 255);
		border-radius: 3vw;
		box-sizing: border-box;
		position: absolute;
		z-index: 2;
		animation: spin 20s infinite linear alternate forwards;
	}
	& .outerreelright {
		@extend .outerreelleft;
		right: 0;
	}
}

.tint {
	@include dimentions(8vw, 4vw, rgba(0, 0, 0, 0.3));
	margin: -4vw auto;
	position: relative;
}

%circle {
	@include dimentions(1vw, 1vw, $back);
	position: absolute;
}

.bottom {
	@include dimentions(20vw, 2vw, transparent);
	border-bottom: 4vw solid $stickerColor;
	border-left: 1.5vw solid transparent;
	border-right: 1.5vw solid transparent;
	margin: 0 auto;
	position: relative;

	& .circle1 {
		@extend %circle;
		border-radius: 2vw;
		margin-left: 1vw;
		bottom: -3.5vw;
	}

	& .circle2 {
		@extend %circle, .circle1;
		right: 1vw;
	}

	& .circle3 {
		@extend %circle;
		border-radius: 0.3vw;
		margin-left: 3.5vw;
		bottom: -2.5vw;
	}

	& .circle4 {
		@extend %circle, .circle3;
		right: 3.5vw;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(3600deg);
	}
}

@keyframes shrink {
	0% {
		box-shadow: 0 0 0 0.01vw $tape;
	}
	100% {
		box-shadow: 0 0 0 3.2vw $tape;
	}
}

@keyframes grow {
	0% {
		box-shadow: 0 0 0 3.2vw $tape;
	}

	100% {
		box-shadow: 0 0 0 0.01vw $tape;
	}
}